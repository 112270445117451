import axios from 'axios';

export const apiUrl = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export const apiAutomationClientUrl = axios.create({
  baseURL: process.env.VUE_APP_API_AUTOMATION_CLIENT_URL,
});

apiUrl.defaults.headers.common.api_secret_key =
  process.env.VUE_APP_API_SECRET_KEY || '';

export const apiDownloadBaseUrl = axios.create({
  baseURL: process.env.VUE_API_DOWNLOAD_BASE_URL,
})